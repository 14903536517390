<template>
  <div style="position: relative;">
    <!--    <div v-if="isAdminUser !== ''">-->
    <a-tabs default-active-key="1" @change="tabChange">
      <!-- <a-tab-pane key="1" tab="质检分组" v-if="$store.state.userType=='system'">
        <RecordList v-if="tabInit.record"   :voiceOption="voiceOption" :amountOption="amountOption"
                :isAdminUser="isAdminUser"/>
      </a-tab-pane> -->
      <a-tab-pane key="2" tab="质检记录">
        <Record v-if="tabInit.record" :voiceOption="voiceOption" :amountOption="amountOption"
                :isAdminUser="isAdminUser"/>
      </a-tab-pane>
      <a-tab-pane key="3" tab="数据统计">
        <statistics v-if="tabInit.statistics" :isAdminUser="isAdminUser" :userOptions="userOptions"
                    :amountOption="amountOption"/>
      </a-tab-pane>
      <a-tab-pane key="4" tab="关键词配置">
        <RuleKey v-if="tabInit.ruleKey" :isAdminUser="isAdminUser" :userOptions="userOptions"
                 :voiceOption="voiceOption"/>
      </a-tab-pane>
      <a-tab-pane key="5" tab="规则配置" v-if="isAdminUser=='system'">
        <!--        <Rule v-if="tabInit.rule" :isAdminUser="isAdminUser" :voiceOption="voiceOption"/>-->
        <RuleTab v-if="tabInit.rule" :isAdminUser="isAdminUser" :voiceOption="voiceOption"/>
      </a-tab-pane>
      <a-tab-pane key="6" tab="配置管理">
        <manage v-if="tabInit.manage" :isAdminUser="isAdminUser" :userOptions="userOptions"
                :amountOption="amountOption"/>
      </a-tab-pane>
      <a-tab-pane key="7" tab="质检白名单" v-if="isAdminUser=='system'">
        <whiteList :isAdminUser="isAdminUser" :userOptions="userOptions"
                :amountOption="amountOption"/>
      </a-tab-pane>
    </a-tabs>
      <el-button class="btnBox" @click="showImg">惩罚处理指南</el-button>
        <el-dialog v-model="bannedUserDialog" title="惩罚处理指南" width="60%"
               :show-close="true"
               :destroy-on-close="true">
               <div class="flex">
                <p style="margin-bottom:25px">线路类型:</p>&nbsp;
              <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
                 <el-radio-button label="1">回拨、直拨</el-radio-button>
                 <el-radio-button label="2">SIP代理</el-radio-button>
                 <el-radio-button label="3">SIP直客</el-radio-button>
                
              </el-radio-group>
               </div>
               
              <div class="demo-image__preview">
             <el-image 
             v-if="tabPosition=='1'"
                 :src="url" 
                 :preview-src-list="srcList">
             </el-image>
               <el-image 
                 v-if="tabPosition=='2'"
                 :src="url1" 
                 :preview-src-list="srcList1">
               </el-image>
               <el-image 
                 v-if="tabPosition=='3'"
                 :src="url2" 
                 :preview-src-list="srcList2">
               </el-image>
             </div>
        </el-dialog> 
  </div>
  <!--  </div>-->

</template>
<script>
import RecordList from './LineQualityInspections/RecordList.vue'
import Record from './LineQualityInspections/Record.vue'
import manage from './LineQualityInspections/manage.vue'
import RuleKey from './LineQualityInspections/RuleKey.vue'
import RuleTab from './LineQualityInspections/RuleTab.vue'
import whiteList from './LineQualityInspections/whiteList.vue'
// import Rule from './LineQualityInspections/Rule.vue'
import statistics from './LineQualityInspections/statistics.vue'
import _lineService from '@/api/open/LineQualityInspections'
import {Request} from "@/http/request";

export default {
  components: {
    RecordList,
    Record,
    whiteList,
    manage,
    RuleKey,
    RuleTab,
    // Rule,
    statistics
  },
  data() {
    return {
      url:require('../../assets/images/hb.png'),
      srcList: [require('../../assets/images/hb.png'),],
      url1:require('../../assets/images/dl.png'),
      srcList1: [require('../../assets/images/dl.png'),],
      url2:require('../../assets/images/zk.png'),
      srcList2: [require('../../assets/images/zk.png'),],
      tabPosition: '1',
      bannedUserDialog:false,
      voiceOption: [],
      amountOption: [],
      userOptions: [],
      isAdminUser: '',
      //初始化，延迟加载
      tabInit: {
        record: true,
        statistics: false,
        ruleKey: false,
        rule: false,
        manage: false,
        whiteList: false
      }
    }
  },
  methods: {
    showImg(){
          this.bannedUserDialog=true
    },
    tabChange(key) {
      // console.log('tabChange', key);
      if (key == 1 && !this.tabInit.record) {
        this.tabInit.record = true
      }
       if (key == 2 && !this.tabInit.record) {
        this.tabInit.record = true
      }
      if (key == 3 && !this.tabInit.statistics) {
        this.tabInit.statistics = true
      }
      if (key == 4 && !this.tabInit.ruleKey) {
        this.tabInit.ruleKey = true
      }
      if (key == 5 && !this.tabInit.rule) {
        this.tabInit.rule = true
      }
      if (key == 6 && !this.tabInit.manage) {
        this.tabInit.manage = true
      }
      if (key == 7 && !this.tabInit.manage) {
        this.tabInit.whiteList = true
      }

    },
    async getAmountList() {
      const res = await _lineService._recordService.getAmountList()
      this.amountOption = res?.data?.map(item => {
        return {label: item.name, value: item.id}
      }) || [];
    },
    async getVoiceName() {
      const res = await _lineService._recordService.getVoiceName()
      this.voiceOption = res.data.map(item => {
        return {label: item.name, value: item.name}
      })
       let toBeDeleted = [];

      this.voiceOption.forEach((item, index) => {
        if (item.label == '未命名话术') {
        toBeDeleted.push(index);
            }
          });
toBeDeleted.reverse().forEach(index => {
    this.voiceOption.splice(index, 1);
})
    },
    async getUserOptions() {
      const res = await _lineService._recordService.queryList()
      let systemOption = {label: '系统', value: 0};//添加一个系统项
      this.userOptions = res?.data?.map(item => {
        return {label: item.nickname, value: item.uid}
      }) || [];
      this.userOptions.splice(0, 0, systemOption);
    },
    async getUserType() {
      const res = await _lineService._recordService.getUserType()
      this.isAdminUser = res?.data;
      console.log(this.isAdminUser);
    }
  },
  created() {
    Promise.all([this.getAmountList(), this.getVoiceName(), this.getUserOptions()]).then(() => {
    }).catch((err) => {
      this.$message.error(err.message)
    })
    this.getUserType()

  },
};
</script>
<style scoped>
.btnBox{
   position: absolute;
   right: 1%;
   top: 0%;
}
.flex{
  align-items: center;

}
.demo-image__preview{
  padding: 0 20px 0 20px;
}
</style>